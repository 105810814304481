<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- <b-card no-body>
        <b-card-body>
            <b-row>
                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                
                    <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sites"
                    label="site_name"
                    class="w-100"
                    placeholder="Project Site"
                    @input="getForms()"
                    v-model="siteData"
                  >
                    <template #selected-option="{ site_name }">
                      <span v-if="site_name.length < 23">{{site_name}}</span>
                      <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                    </template>
                  </v-select>
                </b-col>

            </b-row>
        </b-card-body>
    </b-card> -->

    <b-row class="match-height" v-if="formsList.length > 0">
      
      <b-col
        md="4"
        v-for="(form,index) in formsList"
        @click="redirectRoute(form.adminReport)"
        :key="index"
        v-if="$store.getters.currentUser.role == 'admin' && form.title != 'Lactation Checklist'"
      >
        <!-- <statistic-card-vertical
          :icon="form.icon"
          :statistic="form.title"
          :color="form.color"
          
        /> -->

        <statistic-card-horizontal
          :color="form.color"
          :statistic="form.title"
          count="0"
        />

      </b-col>

      <b-col
        md="4"
        v-for="(form,index) in formsList"
        @click="redirectRoute(form.form.adminReport)"
        :key="index"
        v-if="$store.getters.currentUser.role != 'admin' && form.form.title != 'Lactation Checklist'"
      >
        <!-- <statistic-card-vertical
          :icon="form.form.icon"
          :statistic="form.form.title"
          :color="form.form.color"
          
        /> -->

        <statistic-card-horizontal
          :color="form.form.color"
          :statistic="form.form.title"
          :count="form.count"
          
        />

      </b-col>
      
    </b-row>

    <b-card v-if="formsList.length == 0">
    <b-row class="match-height">

      <b-col md="12" class="text-center">
        {{'No Forms Found'}}
      </b-col>
    
    </b-row>
  </b-card>

      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BCardBody,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import Bus from "../../../event-bus";

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    StatisticCardVertical,
    StatisticCardHorizontal,
    BBreadcrumb
  },
  data() {
    return {
        webUrl: process.env.VUE_APP_SERVER_URL,
        showDismissibleAlert:false,
        formsList:[],
        siteData:null,
        sites:[],
    }
  },
  methods : {

    /*getForms(){

      this.formsList = [];

      if (this.siteData != null) {
        this.formsList = this.siteData.site_custom_forms;
      }

    },*/
    redirectRoute(route_name){
      
      this.$router.push({ name: route_name,params:{site_id:null}})
    },

    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                if (this.sites.length == 1 ) {
                    this.siteData = this.sites[0];
                    this.getForms();
                }
                
                return this.sites;
            }
        });
    },
    getForms(){
      return this.$store.dispatch(POST_API, {
         data:{
           id: this.$store.getters.currentUser.organization,
           role: this.$store.getters.currentUser.role,
         },
         api: '/api/get-custom-forms-by-role'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.formsList = this.$store.getters.getResults.data.custom_forms;
              //console.log(this.formsList);
              if (this.$store.getters.currentUser.role != 'admin') {
                  this.formsList.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))
              }
              return this.formsList;
          }
      });
    },
    counterUpdate(){
      
      return this.$store.dispatch(POST_API, {
          data:{
              role : this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
              site:this.$store.getters.currentUser.site ?this.$store.getters.currentUser.site._id:null
          },
          api : '/api/pending-report-counts'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;

              window.scrollTo(0,0);
          } else {
              var data  = this.$store.getters.getResults.data;
              
              for (var i = 0; i < this.formsList.length ; i++) {
                if (this.formsList[i].form.title == 'Conservancy Supervisor Daily Report') {
                   this.formsList[i].count = data.csdr;
                }

                if (this.formsList[i].form.title == 'Conservancy Supervisor Daily Report on Public Toilets') {
                   this.formsList[i].count = data.csdropt;
                }

                if (this.formsList[i].form.title == 'Mosquito Prevention Report') {
                   this.formsList[i].count = data.mosquito;
                }

                if (this.formsList[i].form.title == 'Bottle Hand Sanitizers Checklist (Day)') {
                   this.formsList[i].count = data.bhsc;
                }

                if (this.formsList[i].form.title == 'Bottle Hand Sanitizers Checklist (Night)') {
                   this.formsList[i].count = data.bhscn;
                }


                if (this.formsList[i].form.title == 'Meeting Room Defects') {
                   this.formsList[i].count = data.mrd;
                }
                
                if (this.formsList[i].form.title == 'Admin Wipes Checklist (Day)') {
                   this.formsList[i].count = data.awcr;

                }

                if (this.formsList[i].form.title == 'Admin Wipes Checklist (Night)') {
                   this.formsList[i].count = data.awcrn;

                }

                if (this.formsList[i].form.title == 'Staircase Hand Sanitizer') {
                   this.formsList[i].count = data.shs;

                }

                if (this.formsList[i].form.title == 'Soap Dispenser') {
                   this.formsList[i].count = data.sd;

                }

                if (this.formsList[i].form.title == 'Total Flash Time') {
                   this.formsList[i].count = data.tft;

                }

                if (this.formsList[i].form.title == 'Floor Traps') {
                   this.formsList[i].count = data.ft;

                }

                if (this.formsList[i].form.title == 'Toilet Seat Sanitizer') {
                   this.formsList[i].count = data.ts;

                }

                if (this.formsList[i].form.title == 'Air Freshener') {
                   this.formsList[i].count = data.af;

                }
                
                if (this.formsList[i].form.title == 'Sanitary Bin') {
                   this.formsList[i].count = data.pb;
                }

                if (this.formsList[i].form.title == 'Hand Sanitizer Battery (Day)') {
                   this.formsList[i].count = data.hsb;

                }

                if (this.formsList[i].form.title == 'Hand Sanitizer & Staircase (Night)') {
                   this.formsList[i].count = data.hss;

                }

                if (this.formsList[i].form.title == 'Warning Letter') {
                   this.formsList[i].count = data.wl;

                }

                if (this.formsList[i].form.title == 'Marker Pens') {
                   this.formsList[i].count = data.mp;

                }

                if (this.formsList[i].form.title == 'Stock Inventory Form') {
                   this.formsList[i].count = data.stinv;

                }

                if (this.formsList[i].form.title == 'Toilet Inspection Checklist') {
                   this.formsList[i].count = data.tic;

                }
                if (this.formsList[i].form.title == 'Quality & Safety Benchmark Assessments') {
                   this.formsList[i].count = data.qsa;
                }

                if (this.formsList[i].form.title == 'Flash Times') {
                   this.formsList[i].count = data.flt;
                }

                if (this.formsList[i].form.title == 'Micron External Checklist') {
                   this.formsList[i].count = data.exc;
                }
              }
          }
      });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:{name:'custom-forms-dashboard'},
        text: 'Form Dashboard',
      },{
        to:null,
        text:'Custom Forms',
        active:true
      }];

      return this.filterReportBreadCrum(item);
    }
    
   
  },
  mounted(){
    //this.getForms();
    //this.allSites();
    Bus.$emit('counter_update');
    this.getForms().then(() => {
      
      if (this.$store.getters.currentUser.role != 'admin') {
        this.counterUpdate();
      }
    });
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
